import React, { ReactNode } from "react"
import { Bar, BarChart as BarChart_recharts, ResponsiveContainer, XAxis, YAxis } from "recharts"

import { IListEntry, sales, salesValueTail } from "../data/ListBar.chart.data"
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from "@/components/ui/avatar"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"

export const ListBarChart: React.FC = () => {
    return <>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-8">
            <DoulChartCard
                _default="bar"
                title="Total Sales"
                description={"Total Sales: " + sales.reduce((sum, sale) => sum + sale.value, 0) + " " + salesValueTail}
                ChartLabel="List"
                ChartValue="list"
                secondChartValue={"bar"}
                secondChartLabel={"Bar Chart"}
                secondChart={
                    <BarChart
                        data={sales}
                        valueTail={salesValueTail}
                    />
                }
            >
                <List
                    data={sales}
                />
            </DoulChartCard>
        </div>
    </>
}

type IDoulChartCard = IChartCard & {
    secondChart: ReactNode,
    secondChartValue: string,
    secondChartLabel: string,
    ChartValue: string,
    ChartLabel: string,
    _default: string,
}
export const DoulChartCard: React.FC<IDoulChartCard> = ({
    title,
    description,
    secondChart,
    secondChartValue,
    secondChartLabel,
    ChartValue,
    ChartLabel,
    children,
    _default,
    className = "col-span-4",
}) => {
    return <>
        <Card className={className}>
            <Tabs defaultValue={_default} className="col-span-4">
                <div className="pr-2 flex items-center gap-4 justify-between">
                    <CardHeader>
                        <CardTitle>{title}</CardTitle>
                        <CardDescription>
                            {description}
                        </CardDescription>
                    </CardHeader>
                    {/*Tabs*/}
                    <TabsList>
                        <TabsTrigger
                            value={secondChartValue}
                            className="text-sm font-medium"
                        >
                            {secondChartLabel}
                        </TabsTrigger>
                        <TabsTrigger
                            value={ChartValue}
                            className="text-sm font-medium"
                        >
                            {ChartLabel}
                        </TabsTrigger>
                    </TabsList>
                </div>
                <CardContent>
                    <TabsContent
                        value={secondChartValue}
                    >
                        {secondChart}
                    </TabsContent>
                    <TabsContent
                        value={ChartValue}
                    >
                        {children}
                    </TabsContent>
                </CardContent>
            </Tabs>
        </Card>
    </>
}

type IChartCard = {
    title: string,
    description: string
    children: React.ReactNode
    className?: string,
}
export const ChartCard: React.FC<IChartCard> = ({
    title,
    description,
    children,
    className = "col-span-4",
}) => {
    return <>
        <Card className={className}>
            <CardHeader>
                <CardTitle>{title}</CardTitle>
                <CardDescription>
                    {description}
                </CardDescription>
            </CardHeader>
            <CardContent>
                {children}
            </CardContent>
        </Card>
    </>
}


type IList = {
    data: IListEntry[]
}
export const List: React.FC<IList> = ({ data }) => {
    return <>
        <div className="space-y-8">
            {data.map((sale: IListEntry) => (
                <div key={sale.label} className="flex items-center">
                    <Avatar className="h-9 w-9">
                        <AvatarImage src={sale.image} alt="Avatar" />
                        <AvatarFallback>{sale.imageFallback}</AvatarFallback>
                    </Avatar>
                    <div className="ml-4 space-y-1">
                        <p className="text-sm font-medium leading-none">{sale.label}</p>
                        <p className="text-sm text-muted-foreground">{sale.subtitle}</p>
                    </div>
                    <div className="ml-auto font-medium">
                        {sale.valueHead + " " + sale.value + " " + sale.valueTail}
                    </div>
                </div>
            ))}
        </div>
    </>
}

type IBarChart = {
    data: IListEntry[],
    valueHead?: string,
    valueTail?: string
}
export const BarChart: React.FC<IBarChart> = ({ data, valueHead, valueTail }) => {
    return <>
        <ResponsiveContainer width="100%" height={350}>
            <BarChart_recharts data={data}>
                <XAxis
                    dataKey="label"
                    stroke="#888888"
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                />
                <YAxis
                    stroke="#888888"
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={(value) => {
                        return `${valueHead ? valueHead : ''}${value}${valueTail ? valueTail : ''}`;
                    }}
                />
                <Bar
                    dataKey="value"
                    fill="currentColor"
                    radius={[4, 4, 0, 0]}
                    className="fill-primary"
                />
            </BarChart_recharts>
        </ResponsiveContainer>
    </>
}

