import React, { Fragment } from "react";
import DashboardPage from "./dashboard.contianer";

export const DashboardModule: React.FC = () => {
  return (
    <Fragment>
      <DashboardPage />
    </Fragment>
  );
};
