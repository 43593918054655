import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"
import { Orders } from "./tabs/dashboard.tabs.orders"
import { Sales } from "./tabs/dashboard.tabs.sales"
import { Marketing } from "./tabs/dashboard.tabs.marketing"
import { Stock } from "./tabs/dashboard.tabs.stock"
// import { CalendarDateRangePicker } from "./components/date-range-picker"

type ITabData = {
  lable: string
  value: string
}
const TabsData: ITabData[] = [
  {
    lable: "Orders",
    value: "orders",
  },
  {
    lable: "Sales",
    value: "sales",
  },
  {
    lable: "Marketing",
    value: "marketing",
  },
  {
    lable: "Stock",
    value: "stock",
  },
]

export default function DashboardPage() {
  return (
    <>
      <div className="md:hidden">
      </div>
      <div className="hidden flex-col md:flex">
        <div className="flex-1 space-y-4 p-8 pt-6">
          {/*
            * Tabs
            */}
          <Tabs defaultValue="orders" className="space-y-4">
            <div className="flex items-center gap-4 justify-start space-y-2">
              <h2 className="text-3xl font-bold tracking-tight">Dashboard</h2>
              <TabsList>
                {TabsData.map((tab) => (
                  <TabsTrigger
                    key={tab.value}
                    value={tab.value}
                    className="text-sm font-medium"
                  >
                    {tab.lable}
                  </TabsTrigger>
                ))}
              </TabsList>
            </div>
            <TabsContent
              value={"orders"}
              children={<Orders />}
              className="space-y-4"
            />
            <TabsContent
              value={"sales"}
              children={<Sales />}
              className="space-y-4"
            />
            <TabsContent
              value={"marketing"}
              children={<Marketing />}
              className="space-y-4"
            />
            <TabsContent
              value={"stock"}
              children={<Stock />}
              className="space-y-4"
            />
          </Tabs>
        </div>
      </div>
    </>
  )
}


