import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { BarChart, ChartCard, DoulChartCard, List } from "../charts/ListBar.chart"
import { MyPieChart } from "../charts/PieChart.chart"
import { MyRadialChart } from "../charts/RadialChart.chart"
import { sales, salesValueTail } from "../data/ListBar.chart.data"

export const Orders: React.FC = () => {
  return <>
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-8">
      {/*
      * TOT: Total Orders Today
      */}

      {/*<div className="col-span-8 grid md:grid-cols-4 lg:grid-cols-8 gap-4">
        <ChartCard className="col-span-2" title="Total Orders Today" description="Total Orders: 20">
          <MyRadialChart />
        </ChartCard>
      </div>*/}
      <div className="col-span-8 grid md:grid-cols-4 lg:grid-cols-8 gap-4">
        <Card className="col-span-2">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Total Orders
            </CardTitle>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="h-4 w-4 text-muted-foreground"
            >
              <path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
            </svg>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">45231.89 BD</div>
            <p className="text-xs text-muted-foreground">
              +20.1% from last month
            </p>
          </CardContent>
        </Card>
      </div>

      {/*
      * TCVTR: Total Completed Vs Total Returned
      */}

      {/*<ChartCard title="Complete Vs Return" description="Total Completed Orders Vs Total Returned Orders">
        <MyPieChart />
      </ChartCard>*/}


      {/*
      * MO: Most Ordered
      */}
      <DoulChartCard
        _default="list"
        title="Most Ordered Items"
        description={"Total Sales: " + sales.reduce((sum, sale) => sum + sale.value, 0) + " " + salesValueTail}
        ChartLabel="List"
        ChartValue="list"
        secondChartValue={"bar"}
        secondChartLabel={"Bar Chart"}
        secondChart={
          <BarChart
            data={sales}
            valueTail={salesValueTail}
          />
        }
      >
        <List
          data={sales}
        />
      </DoulChartCard>

      {/*
      * MR: Most Returned
      */}
      <DoulChartCard
        _default="bar"
        title="Most Returned Items"
        description={"Total Sales: " + sales.reduce((sum, sale) => sum + sale.value, 0) + " " + salesValueTail}
        ChartLabel="List"
        ChartValue="list"
        secondChartValue={"bar"}
        secondChartLabel={"Bar Chart"}
        secondChart={
          <BarChart
            data={sales}
            valueTail={salesValueTail}
          />
        }
      >
        <List
          data={sales}
        />
      </DoulChartCard>


      {/*
      * OBD: Orders Breack Down by
      */}
      <DoulChartCard
        _default="bar"
        title="Orders Breack Down"
        description=""
        ChartValue="bar"
        ChartLabel="Bar"
        secondChartValue={"pie"}
        secondChartLabel={"Pie"}
        secondChart={
          <MyPieChart />
        }
      >
        <BarChart
          data={sales}
          valueTail={salesValueTail}
        />
      </DoulChartCard>

      {/*
      * RBDB: Returns Breack Down by
      */}
      <DoulChartCard
        _default="pie"
        title="Returns Breack Down "
        description=""
        ChartValue="bar"
        ChartLabel="Bar"
        secondChartValue={"pie"}
        secondChartLabel={"Pie"}
        secondChart={
          <MyPieChart />
        }
      >
        <BarChart
          data={sales}
          valueTail={salesValueTail}
        />
      </DoulChartCard>
    </div>
  </>
}
