import { Box, Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import logo from "../assets/logo/logo.png";
import venestusyLogoLite from "../assets/logo/venestusy-logo-lite.png";
import venLogo from "../assets/logo/venestusy-logo.png";
import "./styles.css";
import { RegisterForm } from "./register-form";
import ReactTyped from "react-typed";
import Lottie from "react-lottie";
import animationData from "../assets/lottie/people-producing-transparent.json";
import { SocialMediaXLogo } from "../assets/svg/social-media.x";
import { SocialMediaFacebookLogo } from "../assets/svg/social-media.facebook";
import { SocialMediaInstagramLogo } from "../assets/svg/social-media.instagram";
import "./styles.css";
import { SocialMediaTiktokLogo } from "../assets/svg/social-media.tiktok";
import { LoginForm } from "./login";

export const AuthenticationPage: React.FC = () => {
  const theme = useTheme();

  const [isLogin, setIsLogin] = React.useState<boolean>(false);

  return (
    <Fragment>
      <Box className="bg-cover-image">
        <Grid container justifyContent={"center"} sx={{ height: "100vh" }}>
          <Grid item lg={9} md={12} sm={12} xs={12} alignSelf={"center"}>
            <Stack spacing={3}>
              <Paper
                elevation={20}
                sx={{
                  // minHeight: "60vh",
                  height: "max-content",
                }}
              >
                <Grid container>
                  <Grid item lg={6.5} md={6.5} sm={12} xs={12}>
                    <Box
                      sx={{
                        backgroundColor: "#111B34",
                        height: "100%",
                        paddingTop: 2,
                      }}
                    >
                      <Stack spacing={1}>
                        <Grid container justifyContent={"center"}>
                          <Grid item>
                            <img
                              src={venestusyLogoLite}
                              height={100}
                              width={100}
                              alt="venestusy logo"
                            />
                            <Typography
                              sx={{
                                fontSize: 15,
                                fontWeight: 700,
                                marginTop: "-20px",
                                color: "#F3F3F3",
                              }}
                            >
                              VENESTUSY
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          justifyContent={"center"}
                          sx={{ paddingTop: 2 }}
                        >
                          <Grid
                            item
                            lg={9}
                            md={9}
                            sm={9}
                            xs={9}
                            sx={{ minHeight: "80px" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 16,
                                fontWeight: 500,
                                color: "white",
                              }}
                            >
                              Our innovative platform{" "}
                              <span style={{ fontWeight: 600 }}>
                                {" "}
                                <span
                                  style={{ color: theme.palette.error.main }}
                                >
                                  S
                                </span>
                                CRAP
                              </span>{" "}
                              revolutionizes operations by simplifying{" "}
                              {/* <ReactTyped
                                style={{
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: "white",
                                }}
                                strings={[
                                  "Data Entry .",
                                  "Data Analysis .",
                                  "Inventory Management .",
                                  "Users Management .",
                                  "Reports Generation .",
                                ]}
                                typeSpeed={60}
                                backSpeed={40}
                                backDelay={1500}
                                loop
                                shuffle
                              />*/}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent={"center"}>
                          <Grid item>
                            <Lottie
                              options={{
                                animationData: animationData,
                                loop: true,
                                autoplay: true,
                                rendererSettings: {
                                  preserveAspectRatio: "xMidYMid slice",
                                },
                              }}
                              width={400}
                              height={250}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          justifyContent={"flex-start"}
                          spacing={1}
                          sx={{ paddingTop: 5, paddingBottom: 1 }}
                        >
                          <Grid item>
                            <Box
                              sx={{
                                borderRadius: "50px",
                                border: "2px solid white",
                                width: "25px",
                                height: "25px",
                                textAlign: "center",
                                backgroundColor: "white",
                              }}
                              className="social-media-icon"
                              onClick={() => {
                                window.open(
                                  "https://twitter.com/VENUSTUSY115710",
                                  "_blank"
                                );
                              }}
                            >
                              <SocialMediaXLogo
                                containerHeight={20}
                                containerWidth={20}
                              />
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box
                              sx={{
                                borderRadius: "50px",
                                border: "2px solid white",
                                width: "25px",
                                height: "25px",
                                textAlign: "center",
                                backgroundColor: "white",
                              }}
                              className="social-media-icon"
                              onClick={() => {
                                window.open(
                                  "https://www.facebook.com/people/Venustusy/61551793565372/",
                                  "_blank"
                                );
                              }}
                            >
                              <SocialMediaFacebookLogo
                                containerHeight={20}
                                containerWidth={20}
                              />
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box
                              sx={{
                                borderRadius: "50px",
                                border: "2px solid white",
                                width: "25px",
                                height: "25px",
                                textAlign: "center",
                                backgroundColor: "white",
                              }}
                              className="social-media-icon"
                              onClick={() => {
                                window.open(
                                  "https://www.instagram.com/venustusy.t/",
                                  "_blank"
                                );
                              }}
                            >
                              <SocialMediaInstagramLogo
                                containerHeight={20}
                                containerWidth={20}
                              />
                            </Box>
                          </Grid>

                          <Grid item>
                            <Box
                              sx={{
                                borderRadius: "50px",
                                border: "2px solid white",
                                width: "25px",
                                height: "25px",
                                textAlign: "center",
                                backgroundColor: "white",
                              }}
                              className="social-media-icon"
                              onClick={() => {
                                window.open(
                                  "https://www.tiktok.com/@venustusy?lang=ar",
                                  "_blank"
                                );
                              }}
                            >
                              <SocialMediaTiktokLogo
                                containerHeight={20}
                                containerWidth={20}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={5.5}
                    md={5.5}
                    sm={12}
                    xs={12}
                    sx={{ position: "relative" }}
                  >
                    <Stack justifyContent={"center"} alignItems={"center"}>
                      <img
                        src={logo}
                        height={120}
                        width={150}
                        alt="venestusy logo"
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 700,
                          marginTop: "-20px",
                        }}
                      >
                        <span style={{ color: theme.palette.error.main }}>
                          S
                        </span>
                        CRAP
                      </Typography>
                      <Typography
                        sx={{
                          mt: 6,
                          fontSize: 25,
                          fontWeight: 600,
                          letterSpacing: "1px",
                          color: theme.palette.primary.main,
                        }}
                      >
                        {isLogin ? "LOGIN" : "CREATE ACCOUNT"}
                      </Typography>
                      <Box sx={{ mt: 1 }}>
                        {isLogin ? (
                          <LoginForm setIsLogin={setIsLogin} />
                        ) : (
                          <RegisterForm setIsLogin={setIsLogin} />
                        )}
                      </Box>
                    </Stack>
                    <Box sx={{ position: "absolute", right: 10, bottom: 0 }}>
                      <Stack direction={"row"}>
                        <img
                          alt="Venustusy Logo"
                          src={venLogo}
                          height={40}
                          width={40}
                          style={{ alignSelf: "center" }}
                        />
                        <Typography
                          sx={{
                            fontWeight: 500,
                            alignSelf: "center",
                            ml: -1.2,
                          }}
                        >
                          <em>Ven</em>
                          <span style={{ fontWeight: 700, color: "#0C1339" }}>
                            Auth
                          </span>
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container justifyContent={"flex-end"}>
                <Grid item>
                  <Typography
                    sx={{ fontSize: 11, fontWeight: 700, color: "#959595" }}
                  >
                    Copyright &#169; 2024 - VENESTUSY W.L.L
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
